import logo from '../images/logo/alogo.svg';
export default function Splash() {
  return (
    <div className="splash-container">
      <div className="splash-item">
        <img width={200} src={logo} alt="Logo" />
      </div>
    </div>
  );
}
