import { useScrollTrigger, Slide } from '@mui/material';;

interface HideOnScrollProps {
    children: React.ReactElement;
  }

const HideOnScroll: React.FC<HideOnScrollProps> = ({ children }) => {
    // UseScrollTrigger options with TypeScript
    const trigger = useScrollTrigger({
      target: window, // This can be changed to a specific scrollable div if needed
      threshold: 100,
      disableHysteresis: true,
    });
  
    return (
      <Slide appear={false} direction="up" in={!trigger}>
        {children}
      </Slide>
    );
  };

  export default HideOnScroll;