import React from "react";
import { Box, Typography, styled } from "@mui/material";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "80vh",
}));

const StyledIcon = styled(SentimentDissatisfiedIcon)(({ theme }) => ({
  fontSize: 100,
}));

const NoProductData: React.FC = () => (
  <StyledBox>
    <StyledIcon color="secondary" />
    <Typography color="secondary" variant="h4" gutterBottom>
      Oops!
    </Typography>
    <Typography color="secondary" variant="subtitle1">
      No products found.
    </Typography>
  </StyledBox>
);

export default NoProductData;
