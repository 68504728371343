import { useNavigate } from "react-router-dom";
import { InstallStateStore } from "../store/store";
import Button from "@mui/material/Button";
import SummaryItem from "../components/summary-item";
import TheHeader from "../components/theHeader";

export default function M10InstallerStatus() {
  const navigate = useNavigate();
  const [m10HardwareState, m10SoftwareState] = InstallStateStore((state) => [
    state.m10HardwareState,
    state.m10SoftwareState,
    state.UpdateM10HardwareState,
    state.UpdateM10SoftwareState,
  ]);

  function buttonClick() {
    if (!m10HardwareState) {
      navigate("/m10-hardware");
    } else if (!m10SoftwareState) {
      navigate("/m10-software");
    } else if (m10SoftwareState && m10HardwareState) {
      navigate("/");
    }
  }

  return (
    <>
      <TheHeader
        headerText={"M10 setup summary"}
        icon={"home"}
        iconPress={(): void => navigate("/m10-setup")}
      />
      <div className="m10-summary-container">
        <div className="m10-summary-box">
          <div className="m10-summary-top-box">
            <div className="m10-summary-top-box-item-wrapper">
              <SummaryItem
                title={
                  m10HardwareState
                    ? "PROJECTOR INSTALLATION IS DONE"
                    : "PROJECTOR INSTALLATION IS NOT DONE"
                }
                done={m10HardwareState}
              />

              <SummaryItem
                title={
                  m10SoftwareState
                    ? "PROJECTOR SETUP IS DONE"
                    : "PROJECTOR SETUP IS NOT DONE"
                }
                done={m10SoftwareState}
              />
            </div>
          </div>
          <div className="m10-summary-bottom-box">
            <div className="m10-summary-bottom-box-buttons">
              <Button
                color="primary"
                size="large"
                variant="contained"
                onClick={(): void => buttonClick()}
              >
                {m10HardwareState && m10SoftwareState ? "Home" : "Next"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
