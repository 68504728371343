import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TheHeader from "../components/theHeader";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabPanelTypes } from "../models/types";
import SignIn from "../components/sign-in";
import SignUp from "../components/sign-up";

function TabPanel(props: TabPanelTypes) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function SignInUp() {
  const [value, setValue] = useState(0);
  const theme = useTheme();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // Custom style for inactive tab
  const tabStyle = (isActive: boolean) => ({
    color: isActive ? theme.palette.secondary.main : theme.palette.grey[500]
  });

  return (
    <div style={{ height: "100%" }}>
      <TheHeader
        headerText={value === 0 ? "Sign-in" : "Sign-up"}
        icon={value === 0 ? "person_add" : "login"}
        iconPress={() => (value === 1 ? setValue(0) : setValue(1))}
      />
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            centered
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            aria-label="sign-in and sign-up"
            textColor="secondary"
            indicatorColor="secondary"
          >
            <Tab
              label="Sign-in"
              {...a11yProps(0)}
              sx={() => tabStyle(value === 0)}
            />
            <Tab
              label="Sign-up"
              {...a11yProps(1)}
              sx={() => tabStyle(value === 1)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <SignIn />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SignUp />
        </TabPanel>
      </Box>
    </div>
  );
}
