import React from "react";
import ImageCarousel from "../components/carousel_two";
import { useNavigate } from "react-router-dom";
import TheHeader from "../components/theHeader";
import _x20ImageList from "../data/x20InstallImages";

function X20Setup() {
  const navigate = useNavigate();

  return (
    <>
      <TheHeader
        headerText={"Install the X20"}
        icon={"home"}
        iconPress={(): void => navigate("/")}
      />
      <ImageCarousel imagelist={_x20ImageList} />
    </>
  );
}

export default X20Setup;
