import styled from "styled-components";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

export const Form = styled.form`
  width: 100%;
`;

export const SerialNumberContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
  overflow-x: auto;
  overflow-y: visible;
`;

export const ImageInputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SerialNumberImage = styled.img`
  max-width: 90%;

  @media (min-width: 600px) {
    max-width: 80%;
  }

  @media (min-width: 960px) {
    max-width: 80%;
  }
`;

export const ButtonsWrapper = styled(Box)`
  padding-top: 10px;
  display: flex;
  justify-content: space-between; // Space out the buttons
  gap: 10px; // Optional: add some spacing between buttons
  margin-top: 20px; // Optional: add some space above the buttons
`;

export const InfoText = styled(Typography)`
  color: secondary;
  padding-top: 10px;
`;

export const StyledTextField = styled(TextField)`
  & input {
    color: #ffffff;
  }
  & label {
    color: #ffffff;
  }
  &.Mui-focused fieldset {
    border-color: artome.light;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: #ffffff;
      border-radius: 10px;
    }
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;
