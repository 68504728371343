import React from "react";
import ImageCarousel from "../components/carousel_two";
import { useNavigate } from "react-router-dom";
import TheHeader from "../components/theHeader";
import _m10ImageList from "../data/m10InstallImages";

function M10Setup() {
  const navigate = useNavigate();

  return (
    <>
      <TheHeader
        headerText={"Install the M10"}
        icon={"home"}
        iconPress={(): void => navigate("/")}
      />
      <ImageCarousel imagelist={_m10ImageList} />
    </>
  );
}

export default M10Setup;
