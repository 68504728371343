import { create } from 'zustand';
import { HeaderState, UserLoginState, InstallState, SerialNumbersState, ProductNameState, LensState, productState, versionNumberState, productOwnerState } from '../models/zustand.model';
import { userTabActions } from './actions';

import packageInfo from '../../package.json';

export const headerStore = create<HeaderState>()((set) => ({
    text: 'Header text',
    icon: 'home',
    iconPress: () => { },
    updateText: (text: string) => set(() => ({ text: text })),
    updateIcon: (icon: string) => set(() => ({ icon: icon })),
    //updateIconPress: (iconPress: void) => set((state) => ({ iconPress: state.iconPress })),
    updateIconPress: () => set((state) => ({ iconPress: state.iconPress })),
}))

export const UserLoginStore = create<UserLoginState>()((set) => ({
    userLoginState: false,
    UpdateUserLoginState: (userLoginState: boolean) => set(() => ({ userLoginState: userLoginState })),
}))

export const InstallStateStore = create<InstallState>()((set) => ({
    m10HardwareState: false,
    m10SoftwareState: false,
    x20HardwareState: false,
    x20SoftwareState: false,
    x20LensState: false,
    s1HardwareState: false,
    s1SoftwareState: false,
    UpdateM10HardwareState: (m10HardwareState: boolean) => set(() => ({ m10HardwareState: m10HardwareState })),
    UpdateM10SoftwareState: (m10SoftwareState: boolean) => set(() => ({ m10SoftwareState: m10SoftwareState })),
    UpdateX20HardwareState: (x20HardwareState: boolean) => set(() => ({ x20HardwareState: x20HardwareState })),
    UpdateX20SoftwareState: (x20SoftwareState: boolean) => set(() => ({ x20SoftwareState: x20SoftwareState })),
    UpdateX20LensState: (x20LensState: boolean) => set(() => ({ x20LensState: x20LensState })),
    UpdateS1HardwareState: (s1HardwareState: boolean) => set(() => ({ s1HardwareState: s1HardwareState })),
    UpdateS1SoftwareState: (s1SoftwareState: boolean) => set(() => ({ s1SoftwareState: s1SoftwareState })),
}))

export const SerialNumbersStore = create<SerialNumbersState>()((set) => ({
    artomeSerialNo: '',
    updateArtomeSerialNo: (artomeSerialNo: string) => set(() => ({ artomeSerialNo: artomeSerialNo })),
    epsonSerialNo: '',
    updateEpsonSerialNo: (epsonSerialNo: string) => set(() => ({ epsonSerialNo: epsonSerialNo })),
}))

export const ProductNameStore = create<ProductNameState>()((set) => ({
    productName: '',
    updateProductName: (productName: string) => set(() => ({ productName: productName })),
}))

export const LensStore = create<LensState>()((set) => ({
    lensName: '',
    updateLensName: (lensName: string) => set(() => ({ lensName: lensName })),
}))

export const ProductStore = create<productState>()((set) => ({
    productSerialNo: 'artome-serial-test-x20',
    updateProductSerialNo: (productSerialNo: string) => set(() => ({ productSerialNo: productSerialNo })),
}))

export const UserTabStore = create<userTabActions>()((set) => ({
    tab: 0,
    updateTab: (tab: number) => set(() => ({ tab: tab })),
}))

export const AppVersionStore = create<versionNumberState>(() => ({
    version: packageInfo.version,
}));

export const ProductOwnerStore = create<productOwnerState>()((set) => ({
    ownerCompanyName: '',
    updateOwnerCompanyName: (ownerCompanyName: string) => set(() => ({ ownerCompanyName: ownerCompanyName })),
}));