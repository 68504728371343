import React from "react";
import { useNavigate } from "react-router-dom";
import TheHeader from "../../components/theHeader";

import toolsImage from "../../images/S1/checklist/checklist.png";
import CaruouselItem from "../../components/carouselItem";

export default function S1Tools() {
  const navigate = useNavigate();
  const height: number = window.screen.height - 350;

  return (
    <>
      <TheHeader
        headerText={"Select the product"}
        icon={"home"}
        iconPress={(): void => navigate("/")}
      />
      <CaruouselItem
        showButton={true}
        image={toolsImage}
        text={"Requirement tools for the installation"}
        buttonTitle={"Next"}
        buttonPress={(): void => navigate("/s1-setup")}
        height={height}        
      />
    </>
  );
}
