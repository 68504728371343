import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

interface DeleteUserDialogProps {
  open: boolean;
  handleClose: () => void;
  handleDelete: (email: string, password: string) => void;
  loading?: boolean;
}

const DeleteUserDialog: React.FC<DeleteUserDialogProps> = ({
  open,
  handleClose,
  handleDelete,
  loading = false,
}) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const handleDeleteClick = () => {
    if (email && password) {
      handleDelete(email, password);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="delete-dialog-title">
      <DialogTitle id="delete-dialog-title">Delete Account</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter your email and password to confirm account deletion. This action is permanent.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Email"
          type="email"
          fullWidth
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Password"
          type="password"
          fullWidth
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleDeleteClick}
          color="error"
          variant="contained"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Delete Account"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteUserDialog;