import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  registerWithEmailAndPassword,
} from "../API/firebase";
import MySnackbar from "./snackBar";

export default function SignUp() {
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const [nameError, setNameError] = useState(false);
  const [companyError, setCompanyError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [password2Error, setPassword2Error] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (event: {
    preventDefault: () => void;
    currentTarget: HTMLFormElement | undefined;
  }) => {
    event.preventDefault();

    if (name.length <= 5) {
      setNameError(true);
    } else {
      setNameError(false);
    }

    if (company.length <= 0) {
      setCompanyError(true);
    } else {
      setCompanyError(false);
    }

    if (email.length <= 0) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }

    if (password.length <= 7) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }

    if (password2.length !== password.length) {
      setPassword2Error(true);
    } else {
      setPassword2Error(false);
    }

    if (
      name !== "" &&
      email !== "" &&
      company !== "" &&
      password !== "" &&
      password2 !== ""
    ) {
      try {
        await registerWithEmailAndPassword(name, company, email, password);
        //await emailVerification();
        navigate("/");
      } catch (error: any) {
        if (error === "auth/email-already-in-use") {
          setSnackbarMessage("Email is already in use");
        } else {
          setSnackbarMessage("Something went wrong");
        }
        setOpenSnackbar(true);
      }
    }
  };

  return (
    <div className="sign-x-container">
      <Box
        sx={{
          marginTop: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          overflowY: "auto",
        }}
      >
        <Avatar color="primary" sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" color="secondary">
          Sign up
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <TextField
          color="secondary"
            margin="normal"
            error={nameError}
            helperText={nameError ? "Minimum 5 letters" : ""}
            autoComplete="given-name"
            name="firstName"
            required
            fullWidth
            id="firstName"
            label="Your Name"
            autoFocus
            onChange={(e) => setName(e.target.value)}
            sx={{
              "& input": {
                color: "artome.light",
              },
              // Label color
              "& label": {
                color: "artome.light",
              },
              '&.Mui-focused fieldset': {
                borderColor: 'artome.light', // Border color when focused
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'artome.light',
                  borderRadius: 10,
                },
              },
            }}
          />

          <TextField
          color="secondary"
            error={companyError}
            helperText={companyError ? "Write your company here" : ""}
            margin="normal"
            required
            fullWidth
            id="company"
            label="Company"
            name="company"
            autoComplete="Company"
            onChange={(e) => setCompany(e.target.value)}
            sx={{
              "& input": {
                color: "artome.light",
              },
              // Label color
              "& label": {
                color: "artome.light",
              },
              '&.Mui-focused fieldset': {
                borderColor: 'artome.light', // Border color when focused
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'artome.light',
                  borderRadius: 10,
                },
              },
            }}
          />

          <TextField
          color="secondary"
            type="email"
            error={emailError}
            helperText={emailError ? "Please add your email" : ""}
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              "& input": {
                color: "artome.light",
              },
              // Label color
              "& label": {
                color: "artome.light",
              },
              '&.Mui-focused fieldset': {
                borderColor: 'artome.light', // Border color when focused
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'artome.light',
                  borderRadius: 10,
                },
              },
            }}
          />

          <TextField
          color="secondary"
            error={passwordError}
            helperText={passwordError ? "Minimum 8 characters" : ""}
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="new-password"
            onChange={(e) => setPassword(e.target.value)}
            sx={{
              "& input": {
                color: "artome.light",
              },
              // Label color
              "& label": {
                color: "artome.light",
              },
              '&.Mui-focused fieldset': {
                borderColor: 'artome.light', // Border color when focused
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'artome.light',
                  borderRadius: 10,
                },
              },
            }}
          />

          <TextField
          color="secondary"
            error={password2Error}
            helperText={password2Error ? "Passwords do not match" : ""}
            margin="normal"
            required
            fullWidth
            name="password2"
            label="Repeat Password"
            type="password"
            id="password2"
            autoComplete="new-password2"
            onChange={(e) => setPassword2(e.target.value)}
            sx={{
              "& input": {
                color: "artome.light",
              },
              // Label color
              "& label": {
                color: "artome.light",
              },
              '&.Mui-focused fieldset': {
                borderColor: 'artome.light', // Border color when focused
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'artome.light',
                  borderRadius: 10,
                },
              },
            }}
          />
          <Button
            size="large"
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            <Typography
              component="div"
              sx={{ flexGrow: 1, color: "secondary.main" }}
            >
              Sign Up
            </Typography>
          </Button>
        </Box>
      </Box>
      <MySnackbar
        message={snackbarMessage}
        onClose={function (): void {
          setOpenSnackbar(false);
        }}
        open={openSnackbar}
      />
    </div>
  );
}
