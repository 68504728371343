import React, { useState, FC } from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CheckedIcon from "@mui/icons-material/CheckCircle";
import { ItemSelectItemTypeNew } from "../models/types";
import theme from "../styles/theme";
import { StyledProductCard, StyledProductCardFooter, StyledProductCardImage, StyledProductCardLogo, StyledProductCardText } from "../styles/item-select-item-styles";

const ItemSelectItemNew: FC<ItemSelectItemTypeNew> = ({
  logo,
  image,
  text,
  buttonText,
  buttonPress,
  success,
  containerStyleName,
}) => {
  const [isSelected, setIsSelected] = useState(false);

  const handleItemClick = () => {
    setIsSelected(!isSelected);
    buttonPress?.();
  };

  return (
    <StyledProductCard
      className={`product-card ${containerStyleName} ${isSelected ? "selected" : ""}`}
      onClick={handleItemClick}
    >
      {logo && (
        <StyledProductCardLogo>
          <img src={logo} alt="Logo" />
        </StyledProductCardLogo>
      )}
      <StyledProductCardImage>
        <img src={image} alt="Product" />
      </StyledProductCardImage>
      <StyledProductCardFooter>
        <StyledProductCardText>{text}</StyledProductCardText>
        {success && (
          <IconButton aria-label="check" size="large" color="artome">
            <CheckedIcon color="success" fontSize="inherit" />
          </IconButton>
        )}
        <Button
          variant="contained"
          size="large"
          color="artome"
          sx={{
            fontWeight: "bold",
            width: "100%", // Ensure full width on mobile
            [theme.breakpoints.up('tablet')]: {
              width: "auto", // Adjust width on larger screens
            },
          }}
        >
          {buttonText}
        </Button>
      </StyledProductCardFooter>
    </StyledProductCard>
  );
};

export default ItemSelectItemNew;
