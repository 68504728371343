import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { SerialsContainer } from "../../styles/serial-number-styles";

import sernoImage from "../../images/X20/serial-no/epson-serial-no.png";
import { useNavigate } from "react-router-dom";
import SerialNumberItem from "../../components/serial-number-item";
import TheHeader from "../../components/theHeader";
import { SerialNumbersStore } from "../../store/store";

function ProjectorSno() {
  const [updateEpsonSerialNo] = SerialNumbersStore((state) => [
    state.updateEpsonSerialNo,
  ]);

  const [serialNumber, setSerialNumber] = useState("");
  const [enable, setEnable] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (serialNumber.length >= 10) {
      setEnable(true);
    } else {
      setEnable(false);
    }
  }, [serialNumber]);

  const handleSubmit = (event: {
    preventDefault: () => void;
    currentTarget: HTMLFormElement | undefined;
  }) => {
    event.preventDefault();
    if (enable) {
      updateEpsonSerialNo(serialNumber);
      navigate("/register-owner");
    }
  };

  return (
    <>
      <TheHeader
        headerText={"Projector serial number"}
        icon={"arrow_back"}
        iconPress={(): void => navigate("/register-artome-serial-no")}
      />
      <Container>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid
            component={Box}
            item
            mobile={12}
            tablet={8}
            laptop={6}
            desktop={4}
          >
            <SerialsContainer>
              <SerialNumberItem
                image={sernoImage}
                text={""}
                buttonText={"Next"}
                secondary={true}
                inputOnChange={(e) => setSerialNumber(e)}
                handleSubmit={(e) => handleSubmit(e)}
                enableButton={enable}
                backButtonRoute="/register-artome-serial-no"
                inputLabel="Projector Serial #"
                inputName="esno"
              />
            </SerialsContainer>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default ProjectorSno;
