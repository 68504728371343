import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { getUserProductItemData, removeProduct } from "../API/firebase";
import { useNavigate } from "react-router-dom";
import { ProductItemTypes } from "../models/types";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import { ProductStore } from "../store/store";
import TheHeader from "../components/theHeader";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";

const UserProductItem: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<ProductItemTypes[] | null>(null);
  const [open, setOpen] = useState(false);

  const productSerialNo = ProductStore((state) => state.productSerialNo);

  useEffect(() => {
    getUserProductItemData(productSerialNo)
      .then((data) => {
        setData([data]);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }, [productSerialNo]);

  const handleDeleteClick = () => {
    setOpen(true); // Open the confirmation dialog
  };

  const handleDelete = async () => {
    try {
      await removeProduct(productSerialNo);
      // Update the state to reflect the deletion
      setData((currentData) => {
        // If currentData is null, just return null
        if (!currentData) return null;
  
        // Filter out the deleted product
        return currentData.filter(item => item.artome_serial_no !== productSerialNo);
      });
      navigate("/user-page"); // Redirect after deletion
    } catch (error) {
      console.error("Error deleting product:", error);
    } finally {
      setOpen(false); // Close the dialog
    }
  };
    

  // Confirmation Dialog
  const DeleteConfirmationDialog = () => (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to remove this product?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDelete} color="error" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );

  const ProductItem: React.FC<{ product: ProductItemTypes }> = ({
    product,
  }) => {
    return (
      <div key={product.artome_serial_no}>
        <ListItem>
          <ListItemText
            primary={product.artome_product}
            secondary="Product Name"
          />
        </ListItem>
        <Divider variant="fullWidth" component="li" />

        <ListItem>
          <ListItemText
            primary={product.setup_date}
            secondary="Registration Date"
          />
        </ListItem>
        <Divider variant="fullWidth" component="li" />

        <ListItem>
          <ListItemText
            primary={product.product_owner}
            secondary="Product Owner"
          />
        </ListItem>
        <Divider variant="fullWidth" component="li" />

        <ListItem>
          <ListItemText
            primary={product.artome_serial_no}
            secondary="Artome Serial #"
          />
        </ListItem>
        <Divider variant="fullWidth" component="li" />

        <ListItem>
          <ListItemText
            primary={product.epson_serial_no}
            secondary="Projector Serial #"
          />
        </ListItem>
        <Divider variant="fullWidth" component="li" />

        {product.lens &&
          <><ListItem>
            <ListItemText primary={product.lens} secondary="Lens Serial #" />
          </ListItem><Divider variant="fullWidth" component="li" /></>
        }
      </div>
    );
  };

  const dataLoop = data?.map((d: ProductItemTypes) => (
    <ProductItem key={d.artome_serial_no} product={d} />
  ));

  return (
    <div style={{ height: "100%" }}>
      <TheHeader
        headerText="Product"
        icon="arrow_back"
        iconPress={() => navigate("/user-page")}
      />
      <div className="sign-x-container">
        <div className="user-info-box">
          {loading ? (
            <CircularProgress />
          ) : (
            //<div>{data ? data.email : <CircularProgress />}</div>
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            >
              {dataLoop}
            </List>
          )}

          <div className="user-info-buttons-container">
            <Button
              size="small"
              onClick={handleDeleteClick}
              variant="text"
              color="error"
            >
              Remove product
            </Button>
          </div>
        </div>
      </div>
      <DeleteConfirmationDialog />
    </div>
  );
};

export default UserProductItem;
