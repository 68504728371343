import React, { useState } from "react";

import sernoImage from "../images/M10/serial-no/m10serial.png";
import SerialNumberItem from "../components/serial-number-item";
import { useNavigate } from "react-router-dom";
import TheHeader from "../components/theHeader";
import { SerialNumbersStore } from "../store/store";

function M10ArtomeSno() {
  const [updateArtomeSerialNo] = SerialNumbersStore((state) => [
    state.updateArtomeSerialNo,
  ]);

  const [serialNumber, setSerialNumber] = useState("");
  const [enable, setEnable] = useState(false);
  const navigate = useNavigate();

  const isValidSerialNumber = (input: string) => {
    const pattern = /^.{8,}$/; // any 8 characters
    //const pattern = /^M10\d{2} \d{3}[A-Z]$/; // M10XX YYYZ
    return pattern.test(input);
  };

  const handleSerialNumberChange = (input: string) => {
    setSerialNumber(input);
    setEnable(isValidSerialNumber(input) && input.length >= 8);
  };

  const handleSubmit = (event: {
    preventDefault: () => void;
    currentTarget: HTMLFormElement | undefined;
  }) => {
    event.preventDefault();
    if (serialNumber.length >= 8) {
      updateArtomeSerialNo(serialNumber);
      navigate("/m10-epson-sno");
    }
  };

  return (
    <>
      <TheHeader
        headerText={"m10 artome serial number"}
        icon={"home"}
        iconPress={(): void => navigate("/")}
      />
      <div className="serials-container">
        <div className="serials-item-wrapper">
          <SerialNumberItem
            image={sernoImage}
            text={""}
            buttonText={"Next"}
            secondary={true}
            inputOnChange={handleSerialNumberChange}
            handleSubmit={(e) => handleSubmit(e)}
            enableButton={enable}
            backButtonRoute="/"
            inputLabel="Artome Serial #"
            inputName="asno"
          />
        </div>
      </div>
    </>
  );
}

export default M10ArtomeSno;
