import React, { useState } from "react";

import sernoImage from "../images/X20/serial-no/x20-serial-no.png";
import SerialNumberItem from "../components/serial-number-item";
import { useNavigate } from "react-router-dom";
import TheHeader from "../components/theHeader";
import { SerialNumbersStore } from "../store/store";

function X20ArtomeSno() {
  const [updateArtomeSerialNo] = SerialNumbersStore(
    (state) => [state.updateArtomeSerialNo]
  );

  const [serialNumber, setSerialNumber] = useState("");
  const [enable, setEnable] = useState(false);
  const navigate = useNavigate();

  const isValidSerialNumber = (input: string) => {
    //const pattern = /^X20\d{2} \d{3}[A-Z]$/; // X20XX YYYZ
    const pattern = /^.{8,}$/; // any 9 characters
    return pattern.test(input);
  };

  const handleSerialNumberChange = (input: string) => {
    setSerialNumber(input);
    setEnable(isValidSerialNumber(input) && input.length >= 9);
  };

  const handleSubmit = (event: {
    preventDefault: () => void;
    currentTarget: HTMLFormElement | undefined;
  }) => {
    event.preventDefault();
    if (serialNumber.length >= 9) {
      updateArtomeSerialNo(serialNumber);
      navigate("/x20-epson-sno");
    }
  };

  return (
    <>
      <TheHeader
        headerText={"X20 artome serial number"}
        icon={"arrow_back"}
        iconPress={(): void => navigate("/")}
      />
      <div className="serials-container">
        <div className="serials-item-wrapper">
          <SerialNumberItem
            image={sernoImage}
            text={""}
            buttonText={"Next"}
            secondary={true}
            inputOnChange={handleSerialNumberChange}
            handleSubmit={(e) => handleSubmit(e)}
            enableButton={enable}
            backButtonRoute="/"
            inputLabel="Artome Serial #"
            inputName="asno"
          />
        </div>
      </div>
    </>
  );
}

export default X20ArtomeSno;
