import { ArrayListType } from '../models/types';

import img1 from '../images/X20/software/img1.png';
import img2 from '../images/X20/software/remote.png';
import img3 from '../images/X20/software/img4.png';
import img4 from '../images/X20/software/img2.png';
import img5 from '../images/X20/software/img3.png';
import img6 from '../images/X20/software/img5.png';
import img7 from '../images/X20/software/img7.png';
import img8 from '../images/X20/software/img9.png';
import img9 from '../images/X20/software/img11.png';

const _x20SoftwareImageList: Array<ArrayListType> = [
    {
        image: img1,
        text: "Projector ON by pressing POWER button from Artome X20"
    },
    {
        image: img2,
        text: "Wait until projector calibrates lens and press enter from remote"
    },
    {
        image: img3,
        text: "Select the correct image direction (when 123 reads correctly)"
    },
    {
        image: img4,
        text: "Select the language."
    },
    {
        image: img5,
        text: "Do you want to set the time -> No."
    },
    {
        image: img6,
        text: "Press Geometry button from the remote and select H/V Keystone. Set the values: V–Keystone 30, V–Balance 15, H–Keystone 0, H–Balance 0."
    },
    {
        image: img7,
        text: "Press Lens Shift button from the remote. Set the values: Vert. 2000, Horiz. 0."
    },
    {
        image: img8,
        text: "Navigate to Menu -> Extended -> Home Screen. Set the Home Screen Auto Disp. value to Off."
    },
    {
        image: img9,
        text: "Navigate to Menu -> Extended -> Operation. Set the Sleep Mode value to Off."
    }
];

export default _x20SoftwareImageList;