import React from "react";
//import ItemSelectItem from "../components/itemSelectItem";
import { useNavigate } from "react-router-dom";

import imageHW from "../images/M10/setup/m10-setup-hw.png";
import imageSW from "../images/M10/setup/m10-setup-sw.png";
//import { InstallStateStore } from "../store/store";
import TheHeader from "../components/theHeader";
import ItemSelectItemNew from "../components/itemSelectItemNew";

function M10SetupHome() {
  const navigate = useNavigate();
  /*
  const [m10HardwareState, m10SoftwareState] = InstallStateStore((state) => [
    state.m10HardwareState,
    state.m10SoftwareState,
  ]);
  */

  return (
    <>
      <TheHeader
        headerText={"M10 Setup"}
        icon={"arrow_back"}
        iconPress={(): void => navigate("/")}
      />
      <div className="home-container">
        <div className="home-item-container">
          <ItemSelectItemNew
            image={imageHW}
            text={"Install the Projector"}
            buttonText={"select"}
            buttonPress={() => {
              navigate("/m10-hardware");
            }}
            containerStyleName={"isi-container"}
          />
          <ItemSelectItemNew
            image={imageSW}
            text={"Setup the Projector"}
            buttonText={"select"}
            buttonPress={() => {
              navigate("/m10-software");
            }}
            containerStyleName={"isi-container"}
          />
        </div>
      </div>
    </>
  );
}

export default M10SetupHome;
