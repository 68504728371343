import React, { useEffect, useState } from "react";
import sernoImage from "../images/M10/serial-no/epson-projector-serial.png";
import SerialNumberItem from "../components/serial-number-item";
import { useNavigate } from "react-router-dom";
import TheHeader from "../components/theHeader";
import { SerialNumbersStore } from "../store/store";

function M10EpsonSno() {
  const [updateEpsonSerialNo] = SerialNumbersStore((state) => [
    state.updateEpsonSerialNo,
  ]);

  const [serialNumber, setSerialNumber] = useState("");
  const [enable, setEnable] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (serialNumber.length >= 10) {
      setEnable(true);
    } else {
      setEnable(false);
    }
  }, [serialNumber]);

  const handleSubmit = (event: {
    preventDefault: () => void;
    currentTarget: HTMLFormElement | undefined;
  }) => {
    event.preventDefault();
    if (serialNumber.length >= 8) {
      updateEpsonSerialNo(serialNumber);
      navigate("/m10-setup");
    }
  };

  return (
    <>
      <TheHeader
        headerText={"m10 Epson serial number"}
        icon={"arrow_back"}
        iconPress={(): void => navigate("/m10-artome-sno")}
      />
      <div className="serials-container">
        <div className="serials-item-wrapper">
          <SerialNumberItem
            image={sernoImage}
            text={""}
            buttonText={"Next"}
            secondary={true}
            inputOnChange={(e) => setSerialNumber(e)}
            handleSubmit={(e) => handleSubmit(e)}
            enableButton={enable}
            backButtonRoute="/m10-artome-sno"
            inputLabel="Epson Serial #"
            inputName="esno"
          />
        </div>
      </div>
    </>
  );
}

export default M10EpsonSno;
