import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import sernoImage from "../../images/S1/serial-no/epson-projector-serial.png";
import SerialNumberItem from "../../components/serial-number-item";
import { useNavigate } from "react-router-dom";
import TheHeader from "../../components/theHeader";
import { SerialNumbersStore } from "../../store/store";
import { Paths } from "../../enums/path.enum";

function S1EpsonSno() {
  const [updateEpsonSerialNo] = SerialNumbersStore(
    (state) => [state.updateEpsonSerialNo]
  );

  const [serialNumber, setSerialNumber] = useState("");
  const [enable, setEnable] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (serialNumber.length >= 10) {
      setEnable(true);
    }
    else {
      setEnable(false);
    }
  }, [serialNumber]);

  const handleSubmit = (event: {
    preventDefault: () => void;
    currentTarget: HTMLFormElement | undefined;
  }) => {
    event.preventDefault();
    if (serialNumber.length >= 10) {
      updateEpsonSerialNo(serialNumber);
      navigate(Paths.s1Setup);
    }
  };
  
  return (
    <>
    <TheHeader
        headerText={"Epson serial number"}
        icon={"arrow_back"}
        iconPress={(): void => navigate(Paths.s1ArtomeSno)}
      />
    <Grid
      direction="row"
      alignItems="center"
      justifyContent="center"
      container
      spacing={0}
    >
      <Grid desktop={12} laptop={12} tablet={12} mobile={12}>
        <SerialNumberItem
          image={sernoImage}
          text={""}
          buttonText={"Next"}
          secondary={true}
          inputOnChange={(e) => setSerialNumber(e)}
          handleSubmit={(e) => handleSubmit(e)}
          enableButton={enable}
          backButtonRoute={Paths.s1ArtomeSno}
          inputLabel="Epson Serial #"
          inputName="esno"
          infoText="Compatible models: EB-770F, EB-775F, EB-760W, EB-735F, EB-725W"
        />
      </Grid>
    </Grid>
    </>
  );
}

export default S1EpsonSno;
