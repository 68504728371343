import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import imageLN from "../images/X20/lens/x20-lens-install.png";
import imageHW from "../images/X20/hardware/x20-hardware-install.png";
import imageSW from "../images/X20/software/x20-software-setup.png";
//import { InstallStateStore } from "../store/store";
import TheHeader from "../components/theHeader";
import ItemSelectItemNew from "../components/itemSelectItemNew";
import { Styles } from "../enums/style.enum";

function X20SetupHome() {
  const navigate = useNavigate();
  /*
  const [x20HardwareState, x20SoftwareState, x20LensState] = InstallStateStore(
    (state) => [
      state.x20HardwareState,
      state.x20SoftwareState,
      state.x20LensState,
    ]
  );
  */

  const scrollTargetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollTargetRef.current && window.innerHeight < 768) {
      scrollTargetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <>
      <TheHeader
        headerText={"X20 Setup"}
        icon={"home"}
        iconPress={(): void => navigate("/")}
      />
      <div className="home-container">
        <div className="home-item-container">
          <ItemSelectItemNew
            image={imageLN}
            text={"Install the Lens"}
            buttonText={"select"}
            buttonPress={() => {
              navigate("/x20-lens");
            }}
            containerStyleName={Styles.productItemContainer}
          />
          <ItemSelectItemNew
            image={imageHW}
            text={"Install the Projector"}
            buttonText={"select"}
            buttonPress={() => {
              navigate("/x20-hardware");
            }}
            containerStyleName={Styles.productItemContainer}
          />

          <ItemSelectItemNew
            image={imageSW}
            text={"Setup the Projector"}
            buttonText={"select"}
            buttonPress={() => {
              navigate("/x20-software");
            }}
            containerStyleName={Styles.productItemContainer}
          />
        </div>
      </div>
    </>
  );
}

export default X20SetupHome;
