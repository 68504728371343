import React, { 
  //useEffect 
} from "react";
import { useNavigate } from "react-router-dom";
import {
  InstallStateStore,
  //LensStore,
  //ProductNameStore,
  //SerialNumbersStore,
} from "../store/store";
import Button from "@mui/material/Button";
import SummaryItem from "../components/summary-item";
//import { x20ProvisioningRecords } from "../API/firebase";

export default function X20InstallerStatus() {
  const navigate = useNavigate();

  const [x20HardwareState, x20SoftwareState, x20LensState] = InstallStateStore(
    (state) => [
      state.x20HardwareState,
      state.x20SoftwareState,
      state.x20LensState,
      state.UpdateX20HardwareState, state.UpdateX20SoftwareState, state.UpdateX20LensState
    ]
  );

  /*

  const [artomeSerialNo, epsonSerialNo] = SerialNumbersStore((state) => [
    state.artomeSerialNo,
    state.epsonSerialNo,
  ]);

  const [lensName] = LensStore((state) => [state.lensName]);
  const [productName] = ProductNameStore((state) => [state.productName]);

  useEffect(() => {
    if (x20SoftwareState && x20HardwareState && x20LensState) {
      x20ProvisioningRecords(
        epsonSerialNo,
        artomeSerialNo,
        lensName,
        productName,
        x20LensState,
        x20HardwareState,
        x20SoftwareState
      );
    }
  });
  */

  function buttonClick() {
    if (!x20HardwareState) {
      navigate("/x20-hardware");
    } else if (!x20SoftwareState) {
      navigate("/x20-software");
    } else if (!x20LensState) {
      navigate("/x20-lens");
    } else if (x20SoftwareState && x20HardwareState && x20LensState) {
      navigate("/");
    }
  }

  return (
    <div className="m10-summary-container">
      <div className="m10-summary-box">
        <div className="m10-summary-top-box">
          <div className="m10-summary-top-box-item-wrapper">
            <SummaryItem
              title={
                x20LensState
                  ? "LENS INSTALLATION IS DONE"
                  : "LENS INSTALLATION IS NOT DONE"
              }
              done={x20LensState}
            />

            <SummaryItem
              title={
                x20HardwareState
                  ? "PROJECTOR INSTALLATION IS DONE"
                  : "PROJECTOR INSTALLATION IS NOT DONE"
              }
              done={x20HardwareState}
            />

            <SummaryItem
              title={
                x20SoftwareState
                  ? "PROJECTOR SETUP IS DONE"
                  : "PROJECTOR SETUP IS NOT DONE"
              }
              done={x20SoftwareState}
            />
          </div>
        </div>
        <div className="m10-summary-bottom-box">
          <div className="m10-summary-bottom-box-buttons">
            <Button
              color="primary"
              size="large"
              variant="contained"
              onClick={(): void => buttonClick()}
            >
              {x20LensState && x20HardwareState && x20SoftwareState
                ? "Home"
                : "Next"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
