import styled from "styled-components";
import { Box } from "@mui/material";
import theme from "./theme";

export const HomeContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  height: 100%;
  justify-content: center;
  overflow-y: auto;
  position: relative;

  @media (min-width: ${theme.breakpoints.values.mobile}px) and (max-width: ${theme.breakpoints.values.tablet - 1}px) {
    justify-content: flex-start;
  }

  @media (min-width: ${theme.breakpoints.values.tablet}px) and (max-width: ${theme.breakpoints.values.laptop - 1}px) {
    justify-content: flex-start;
  }

  @media (min-width: ${theme.breakpoints.values.laptop}px) and (max-width: ${theme.breakpoints.values.desktop - 1}px) {
    justify-content: center;
  }

  @media (min-width: ${theme.breakpoints.values.desktop}px) {
    justify-content: center;
  }
`;

export const HomeItemContainer = styled(Box)`
  display: grid;
  gap: 1%;
  justify-content: center;
  padding: 1rem;
  align-items: center;
  box-sizing: border-box;
  transition: background-color 0.3s;
  cursor: pointer;

  @media (max-width: ${theme.breakpoints.values.mobile}px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: ${theme.breakpoints.values.tablet}px) and (max-width: ${theme.breakpoints.values.laptop - 1}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${theme.breakpoints.values.laptop}px) and (max-width: ${theme.breakpoints.values.desktop - 1}px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: ${theme.breakpoints.values.desktop}px) {
    grid-template-columns: repeat(3, 34%);
  }

  @media (min-width: ${theme.breakpoints.values.ultraLarge}px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
