import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography, { TypographyProps } from "@mui/material/Typography";

export const StyledProductCard = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
  margin: "auto",
  color: "#ffffff",
  backgroundColor: "#4B4B4B",
  borderRadius: "10px",
  overflow: "hidden",
  transition: "background-color 0.3s",
  width: "100%",
  aspectRatio: "5 / 7",
  "&.selected": {
    border: "2px solid #ECAA1D",
  },
  [theme.breakpoints.up('mobile')]: {
    width: "100%",
  },
  [theme.breakpoints.up('tablet')]: {
    width: "100%",
  },
  [theme.breakpoints.up('laptop')]: {
    width: "100%",
  },
  [theme.breakpoints.up('desktop')]: {
    width: "100%",
  },
}));

export const StyledProductCardLogo = styled(Box)(({ theme }) => ({
  padding: "35px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  img: {
    width: "70%",
  },
  [theme.breakpoints.up('tablet')]: {
    padding: "20px",
  },
  [theme.breakpoints.up('laptop')]: {
    padding: "10px",
  },
  [theme.breakpoints.up('desktop')]: {
    padding: "5px",
  },
}));

export const StyledProductCardImage = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  img: {
    width: "100%",
    height: "auto",
  },
  [theme.breakpoints.up('mobile')]: {
    img: {
      width: "78%",
    },
  },
  [theme.breakpoints.up('tablet')]: {
    img: {
      width: "90%",
    },
  },
  [theme.breakpoints.up('laptop')]: {
    padding: "0.25em",
  },
  [theme.breakpoints.up('desktop')]: {
    padding: "0.1em",
  },
}));

export const StyledProductCardFooter = styled(Box)(({ theme }) => ({
  padding: "1em",
  textAlign: "center",
  [theme.breakpoints.up('mobile')]: {
    padding: "1em",
  },
  [theme.breakpoints.up('tablet')]: {
    padding: "0.8em",
  },
  [theme.breakpoints.up('laptop')]: {
    padding: "0.5em",
  },
  [theme.breakpoints.up('desktop')]: {
    padding: "0.2em",
  },
}));

export const StyledProductCardText = styled(Typography)<TypographyProps>(({ theme }) => ({
  marginBottom: "1em",
  [theme.breakpoints.up('tablet')]: {
    marginBottom: "0.8em",
  },
  [theme.breakpoints.up('laptop')]: {
    marginBottom: "0.5em",
  },
  [theme.breakpoints.up('desktop')]: {
    marginBottom: "0.2em",
  },
}));
