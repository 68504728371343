import React from "react";
import "./App.css";

import MyFooter from "./components/footer";

import { Routes, Route } from "react-router-dom";

import Home from "./routes/home";
import ErrorPage from "./routes/error-page";
import { ProtectedRoute } from "./routes/protected-route";
import M10ArtomeSno from "./routes/m10-artome-sno";
import M10EpsonSno from "./routes/m10-epson-sno";
import M10HardwareSetup from "./routes/m10-hardware-setup";
import M10InstallerStatus from "./routes/m10-installer-status";
import M10SoftwareSetup from "./routes/m10-software-setup";
import X20ArtomeSno from "./routes/x20-artome-sno";
import X20EpsonSno from "./routes/x20-epson-sno";
import X20SetupHome from "./routes/x20-setup-home";
import X20LensInstall from "./routes/x20-lens-install";
import X20HardwareInstall from "./routes/x20-hardware-install";
import X20InstallerStatus from "./routes/x20-installer-status";
import X20SoftwareInstall from "./routes/x20-software-install";
import X20LensSelect from "./routes/x20-lens-select";
import ForgotPassword from "./routes/forgot-password";
import SignInUp from "./routes/sign-in-up";
import UserPage from "./routes/user-page";
import UserProductItem from "./routes/user-product-item";
import S1ArtomeSno from "./routes/S1/s1-artome-sno";
import S1EpsonSno from "./routes/S1/s1-epson-sno";
import S1InstallerStatus from "./routes/S1/s1-installer-status";
import S1Tools from "./routes/S1/s1-tools";
import ArtomeSno from "./routes/product-register/artome-sno";
import EpsonSno from "./routes/product-register/projector-sno";
import RegisterDone from "./routes/product-register/register-done";
import RegisterOwner from "./routes/product-register/register-owner";
import M10Setup from "./routes/m10-setup";
import S1Setup from "./routes/s1-setup";
import M10SetupHome from "./routes/m10-setup-home";
import S1SetupHome from "./routes/S1/s1-setup-home";
import X20Setup from "./routes/x20-setup";

function App() {
  return (
    <div className="app-container">
      <div className="routes-container">
        <Routes>
          <Route path="sign-in-up" element={<SignInUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/" element={<ProtectedRoute />}>
            <Route path="*" element={<ErrorPage />} />
            <Route path="/" element={<Home />} />
            <Route path="user-page" element={<UserPage />} />

            <Route path="s1-tools" element={<S1Tools />} />
            <Route path="s1-artome-sno" element={<S1ArtomeSno />} />
            <Route path="s1-epson-sno" element={<S1EpsonSno />} />
            <Route path="s1-setup" element={<S1Setup />} />
            <Route path="s1-status" element={<S1InstallerStatus />} />
            <Route path="s1-setup-legacy" element={<S1SetupHome />} />

            <Route path="m10-artome-sno" element={<M10ArtomeSno />} />
            <Route path="m10-epson-sno" element={<M10EpsonSno />} />
            <Route path="m10-setup" element={<M10Setup />} />
            <Route path="m10-hardware" element={<M10HardwareSetup />} />
            <Route path="m10-software" element={<M10SoftwareSetup />} />
            <Route path="m10-status" element={<M10InstallerStatus />} />
            <Route path="m10-setup-gen1" element={<M10SetupHome />} />

            <Route path="x20-artome-sno" element={<X20ArtomeSno />} />
            <Route path="x20-epson-sno" element={<X20EpsonSno />} />
            <Route path="x20-setup" element={<X20Setup />} />
            <Route path="x20-lens" element={<X20LensInstall />} />
            <Route path="x20-hardware" element={<X20HardwareInstall />} />
            <Route path="x20-software" element={<X20SoftwareInstall />} />
            <Route path="x20-status" element={<X20InstallerStatus />} />
            <Route path="x20-lens-select" element={<X20LensSelect />} />
            <Route path="x20-setup-gen1" element={<X20SetupHome />} />

            <Route path="register-artome-serial-no" element={<ArtomeSno />} />
            <Route path="register-projector-serial-no" element={<EpsonSno />} />
            <Route path="register-owner" element={<RegisterOwner />} />
            <Route path="register-done" element={<RegisterDone />} />

            <Route path="user-product/:product" element={<UserProductItem />} />
          </Route>
        </Routes>
      </div>
      <MyFooter />
    </div>
  );
}

export default App;
