import styled from 'styled-components';
import Box from '@mui/material/Box';

const SerialsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #888 #e0e0e0;
  -ms-overflow-style: none;
  align-items: center;
  justify-content: center;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #e0e0e0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
  }
`;

const SerialsItemWrapper = styled(Box)`
  display: grid;
  place-items: center;
  grid-template-columns: repeat(1, minmax(300px, auto));
  padding-bottom: 180px;
`;

export { SerialsContainer, SerialsItemWrapper };
