import React, { useState } from "react";

import sernoImage from "../../images/S1/serial-no/s1serial.png";
import SerialNumberItem from "../../components/serial-number-item";
import { useNavigate } from "react-router-dom";
import TheHeader from "../../components/theHeader";
import { SerialNumbersStore } from "../../store/store";

function S1ArtomeSno() {
  const [updateArtomeSerialNo] = SerialNumbersStore((state) => [
    state.updateArtomeSerialNo,
  ]);

  const [serialNumber, setSerialNumber] = useState("");
  const [enable, setEnable] = useState(false);
  const navigate = useNavigate();

  const isValidSerialNumber = (input: string) => {
    // const pattern = /^S1\d{2} \d{3}[A-Z]$/; // S10X YYYZ (S110 123A)
    const pattern = /^.{8,}$/; // any 8 characters
    return pattern.test(input);
  };

  const handleSerialNumberChange = (input: string) => {
    setSerialNumber(input);
    setEnable(isValidSerialNumber(input) && input.length >= 8);
  };

  const handleSubmit = (event: {
    preventDefault: () => void;
    currentTarget: HTMLFormElement | undefined;
  }) => {
    event.preventDefault();
    if (serialNumber.length >= 8) {
      updateArtomeSerialNo(serialNumber);
      navigate("/s1-epson-sno");
    }
  };

  return (
    <>
      <TheHeader
        headerText={"Artome S1 serial number"}
        icon={"home"}
        iconPress={(): void => navigate("/")}
      />
      <div className="serials-container">
        <div className="serials-item-wrapper">
          <SerialNumberItem
            image={sernoImage}
            text={""}
            buttonText={"Next"}
            secondary={true}
            inputOnChange={handleSerialNumberChange}
            handleSubmit={(e) => handleSubmit(e)}
            enableButton={enable}
            backButtonRoute="/"
            inputLabel="Artome Serial #"
            inputName="asno"
          />
        </div>
      </div>
    </>
  );
}

export default S1ArtomeSno;
