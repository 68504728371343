import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  ProductNameStore,
  ProductOwnerStore,
  SerialNumbersStore,
} from "../../store/store";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import SummaryItem from "../../components/summary-item";
import TheHeader from "../../components/theHeader";
import { registrationRecords } from "../../API/firebase";
import styled from "styled-components";

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 970px;
  height: 100%;
  margin: -60px auto;
  color: #ffffff;
`;

const SummaryBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const SummaryTopBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90%;
`;

const SummaryTopBoxItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 50%;
`;

const SummaryRestItemsContainer = styled.div`
  margin: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  gap: 10px;
  margin-left: 15px;
  padding: 20px;
  border: solid 1px rgba(255, 255, 255, 0.5);
  border-radius: 25px;
`;

const SummaryBottomBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
`;

const SummaryBottomBoxButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
`;

export default function RegisterDone() {
  const navigate = useNavigate();

  const [
    artomeSerialNo,
    epsonSerialNo,
    updateArtomeSerialNo,
    updateEpsonSerialNo,
  ] = SerialNumbersStore((state) => [
    state.artomeSerialNo,
    state.epsonSerialNo,
    state.updateArtomeSerialNo,
    state.updateEpsonSerialNo,
  ]);

  const [productName, updateProductName] = ProductNameStore((state) => [
    state.productName,
    state.updateProductName,
  ]);
  const [ownerCompanyName] = ProductOwnerStore((state) => [
    state.ownerCompanyName,
  ]);

  const [isLoading, setIsLoading] = useState(false);
  const [buttonText, setButtonText] = useState("save");
  const [isDone, setIsDone] = useState(false);
  const [missingFields, setMissingFields] = useState<string[]>([]);

  useEffect(() => {
    const fields = [];
    if (!ownerCompanyName) fields.push("Owner company name");
    if (!artomeSerialNo) fields.push("Artome serial number");
    if (!epsonSerialNo) fields.push("Epson serial number");
    setMissingFields(fields);
  }, [productName, ownerCompanyName, artomeSerialNo, epsonSerialNo]);

  async function saveToDatabase(): Promise<void> {
    if (missingFields.length === 0) {
      setIsLoading(true);
      await registrationRecords(
        epsonSerialNo,
        artomeSerialNo,
        productName,
        ownerCompanyName
      );
      setIsDone(true);
      setTimeout(() => {
        setIsLoading(false);
        setButtonText("done");
        updateArtomeSerialNo("");
        updateEpsonSerialNo("");
        updateProductName("");
      }, 1000);
    }
  }

  function handleSaveClick(): void {
    saveToDatabase().catch((error) => {
      console.error("Error saving to database:", error);
    });
  }

  return (
    <>
      <TheHeader
        headerText={"Registration summary"}
        icon={"home"}
        iconPress={(): void => navigate("/")}
      />
      <SummaryContainer>
        <SummaryBox>
          <SummaryTopBox>
            <SummaryTopBoxItemWrapper>
              <SummaryItem
                title={isDone && !isLoading ? "REGISTRATION COMPLETE" : ""}
                done={isDone && !isLoading ? true : false}
              />
              {artomeSerialNo && epsonSerialNo && (
                <SummaryRestItemsContainer>
                  <h3>Information:</h3>
                  <span>Product name: {productName.toUpperCase()}</span>
                  <span>Owner: {ownerCompanyName}</span>
                  <span>Artome #: {artomeSerialNo.toUpperCase()}</span>
                  <span>Epson #: {epsonSerialNo}</span>
                </SummaryRestItemsContainer>
              )}
              {missingFields.length > 0 && !isDone && (
                <div>
                  <h3>Missing Fields:</h3>
                  <ul>
                    {missingFields.map((field) => (
                      <li key={field}>{field}</li>
                    ))}
                  </ul>
                </div>
              )}
            </SummaryTopBoxItemWrapper>
          </SummaryTopBox>
          <SummaryBottomBox>
            <SummaryBottomBoxButtons>
              <Button
                color="secondary"
                size="large"
                variant="contained"
                onClick={() => navigate(-1)}
                sx={{ width: "100px" }}
                disabled={isDone || isLoading}
              >
                Back
              </Button>
              <Button
                color="primary"
                size="large"
                variant="contained"
                onClick={
                  isDone && !isLoading ? () => navigate("/") : handleSaveClick
                }
                sx={{ width: "100px" }}
                disabled={missingFields.length > 0 && !isDone}
              >
                {isLoading ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  buttonText
                )}
              </Button>
            </SummaryBottomBoxButtons>
          </SummaryBottomBox>
        </SummaryBox>
      </SummaryContainer>
    </>
  );
}
