import { ArrayListType } from '../models/types';

import img1 from '../images/X20/lens/img1.png';
import img2 from '../images/X20/lens/img2.png';
import img3 from '../images/X20/lens/img3.png';
import img4 from '../images/X20/lens/img4.png';
import img5 from '../images/X20/lens/img5.png';

const _x20LensImageList: Array<ArrayListType> = [
    {
        image: img1,
        text: "Remove covers"
    },
    {
        image: img2,
        text: "Remove the lens unlocking blocker"
    },
    {
        image: img3,
        text: "Install lens in the correct position."
    },
    {
        image: img4,
        text: "Lock the lens."
    },
    {
        image: img5,
        text: "Reinstall the cover."
    }
];

export default _x20LensImageList;