import React from "react";
import { useNavigate } from "react-router-dom";
import TheHeader from "../components/theHeader";
import { productItems } from "../data/productItems";
import { Paths } from "../enums/path.enum";
import ItemSelectItemNew from "../components/itemSelectItemNew";
import { HomeContainer, HomeItemContainer } from "../styles/home-styles";

function Home() {
  const navigate = useNavigate();

  return (
    <>
      <TheHeader
        headerText={"Select the product"}
        icon={"person"}
        iconPress={(): void => navigate(Paths.userPage)}
      />
      <HomeContainer>
        <HomeItemContainer>
          {productItems.map((product, index) => (
            <ItemSelectItemNew
              key={index}
              logo={product.logo}
              image={product.image}
              text={""}
              buttonText={product.buttonText}
              buttonPress={() => {
                navigate(product.path);
              }}
              containerStyleName={product.styles}
            />
          ))}
        </HomeItemContainer>
      </HomeContainer>
    </>
  );
}

export default Home;
