import { ArrayListType } from '../models/types';

import img1 from '../images/M10/hardware/img1.png';
import img2 from '../images/M10/hardware/img2.png';
import img3 from '../images/M10/hardware/img3.png';
import img4 from '../images/M10/hardware/img4.png';
import img5 from '../images/M10/hardware/img5.png';
import img6 from '../images/M10/hardware/img6.png';
import img7 from '../images/M10/hardware/img7.png';
import img8 from '../images/M10/hardware/img8.png';
import img9 from '../images/M10/hardware/img9.png';
import img10 from '../images/M10/hardware/img10.png';
import img11 from '../images/M10/hardware/img11.png';
import img12 from '../images/M10/hardware/img12.png';
import img13 from '../images/M10/hardware/img13.png';
import img14 from '../images/M10/hardware/img14.png';

const _x10HardwareImageList: Array<ArrayListType> = [
    {
        image: img1,
        text: "Open and remove the front door"
    },
    {
        image: img2,
        text: "Remove the speaker cover"
    },
    {
        image: img3,
        text: "Remove the back cover by removing the screws."
    },
    {
        image: img4,
        text: "Remove the front cover of the projector."
    },
    {
        image: img5,
        text: "Disconnect the user panel cable."
    },
    {
        image: img6,
        text: "Mount the projector inside the cabinet and align the feet with the holes in the cabinet plane."
    },
    {
        image: img7,
        text: "Lower the feet of the projector into the holes by turning the adjustment wheels."
    },
    {
        image: img8,
        text: "Fix the projector to the cabinet with the optional screws."
    },
    {
        image: img9,
        text: "Connect the user panel cable."
    },
    {
        image: img10,
        text: "Connect all marked cables to the projector's connectors."
    },
    {
        image: img11,
        text: "Attach the back cover with the screws."
    },
    {
        image: img12,
        text: "Install the speaker cover."
    },
    {
        image: img13,
        text: "Fasten the door. Align the door with the adjusting screws if needed (PH2)."
    },
    {
        image: img14,
        text: "Installation is ready."
    }
];

export default _x10HardwareImageList;