import React, { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import TheHeader from "../../components/theHeader";
import styled from "styled-components";
import { useTheme } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { getUserData } from "../../API/firebase";
import { UserDataTypes } from "../../models/types";
import { ProductOwnerStore } from "../../store/store";

import { SerialsContainer } from "../../styles/serial-number-styles";
import Box from "@mui/material/Box";

const SerialItemWrapper = styled(Box)`
  margin: 20px;
  margin-bottom: 50px;
`;

const CustomFormLabel = styled(FormLabel)`
  && {
    color: ${(props) => props.theme.palette.artome.main};
  }
`;

const CustomRadio = styled(Radio)`
  && {
    color: white;
    &.Mui-checked {
      color: ${(props) => props.theme.palette.primary.main};
    }
  }
`;

const CustomFormControlLabel = styled(FormControlLabel)`
  && .MuiFormControlLabel-label {
    color: white;
  }
`;

const SummaryBottomBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
`;

const SummaryBottomBoxButtons = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
`;

const StyledTextField = styled(TextField)`
  && .MuiInputBase-input {
    color: white;
  }
  && .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: white;
  }
  && .MuiInputLabel-root {
    color: white;
  }
  &&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.palette.artome.main};
  }
  &&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.palette.artome.main};
  }
  &&.Mui-focused .MuiInputLabel-root {
    color: ${(props) => props.theme.palette.artome.main};
  }
`;

function RegisterOwner() {
  const navigate = useNavigate();
  const theme = useTheme();

  const [updateOwnerCompanyName] = ProductOwnerStore((state) => [
    state.updateOwnerCompanyName,
  ]);

  const [value, setValue] = React.useState("no-add");
  const [organizationName, setOrganizationName] = React.useState("");
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<UserDataTypes | null>(null);

  useEffect(() => {
    getUserData()
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleOrganizationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOrganizationName(event.target.value);
  };

  const handleSubmit = () => {
    const companyName =
      value === "add" ? organizationName : data?.company || "";
    updateOwnerCompanyName(companyName);
    navigate("/register-done");
  };

  const handleBackButtonClick = () => {
    navigate("/register-projector-serial-no");
  };

  const isButtonDisabled = () => {
    if (value === "no-add") return false;
    if (value === "add" && organizationName.trim() !== "") return false;
    return true;
  };

  return (
    <>
      <TheHeader
        headerText={"Owner information"}
        icon={"arrow_back"}
        iconPress={handleBackButtonClick}
      />
      <SerialsContainer>
        {loading ? (
          <CircularProgress color="primary" />
        ) : (
          <SerialItemWrapper>
            <FormControl>
              <CustomFormLabel id="radio-buttons-group" theme={theme}>
                Warranty must be registered for the owner organization of the
                product. Please select below:
              </CustomFormLabel>
              <RadioGroup
                aria-labelledby="radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={handleChange}
              >
                <CustomFormControlLabel
                  value="no-add"
                  control={<CustomRadio theme={theme} />}
                  label={
                    <span>
                      I am part of the owner organization:{" "}
                      <strong>
                        {data?.company || "No company information available"}
                      </strong>
                    </span>
                  }
                />
                <CustomFormControlLabel
                  value="add"
                  control={<CustomRadio theme={theme} />}
                  label="I’m registering for another organization:"
                />
              </RadioGroup>
              {value === "add" && (
                <StyledTextField
                  theme={theme}
                  label="Organization Name"
                  variant="outlined"
                  value={organizationName}
                  onChange={handleOrganizationChange}
                  margin="normal"
                  fullWidth
                />
              )}
            </FormControl>
            <SummaryBottomBox>
              <SummaryBottomBoxButtons>
                <Button
                  color="secondary"
                  size="large"
                  variant="contained"
                  onClick={handleBackButtonClick}
                >
                  Back
                </Button>
                <Button
                  color="primary"
                  size="large"
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={isButtonDisabled()}
                >
                  Next
                </Button>
              </SummaryBottomBoxButtons>
            </SummaryBottomBox>
          </SerialItemWrapper>
        )}
      </SerialsContainer>
    </>
  );
}

export default RegisterOwner;
