import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { sendPasswordReset } from "../API/firebase";
import TheHeader from "../components/theHeader";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  const handleSubmit = (event: {
    preventDefault: () => void;
    currentTarget: HTMLFormElement | undefined;
  }) => {
    event.preventDefault();

    if (email !== "") {
      sendPasswordReset(email);
    }
  };

  return (
    <div style={{ height: "100%" }}>
      <TheHeader
        headerText={"Reset Password"}
        icon={"login"}
        iconPress={(): void => navigate("/sign-in")}
      />
      <div className="sign-x-container">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar color="primary" sx={{ m: 1, bgcolor: "primary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" color="secondary">
            Reset password
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <TextField
            color="secondary"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
              sx={{
                "& input": {
                  color: "artome.light",
                },
                // Label color
                "& label": {
                  color: "artome.light",
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'artome.light',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'artome.light',
                    borderRadius: 10,
                  },
                },
              }}
            />
            <Button
              size="large"
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              <Typography
                component="div"
                sx={{ flexGrow: 1, color: "secondary.main" }}
              >
                Send email
              </Typography>
            </Button>
          </Box>
        </Box>
      </div>
    </div>
  );
}
