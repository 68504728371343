import React, { FC, useRef, useState, useEffect } from "react";
import { Grid, Button, Fab, IconButton, useTheme } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CloseIcon from "@mui/icons-material/Close";
import { carouslItemTypes } from "../models/types";
import {
  CarouselItemBottomButton,
  CarouselItemButton,
  CarouselItemButtonWrapper,
  CarouselItemContainer,
  CarouselItemSpan,
  CarouselItemTextButtonWrapper,
  ImageWrapper,
  FullscreenButtonWrapper,
  FullscreenImageContainer,
} from "../styles/carousel-styles";

const CarouselItem: FC<carouslItemTypes> = ({
  image,
  text,
  buttonTitle,
  index,
  buttonPress,
  showButton,
  link,
  linkText,
}) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const theme = useTheme();

  const handleFullscreen = () => {
    if (imgRef.current) {
      const imgElement: any = imgRef.current;
      if (imgElement.requestFullscreen) {
        imgElement.requestFullscreen();
      } else if (imgElement.webkitRequestFullscreen) {
        /* Safari */
        imgElement.webkitRequestFullscreen();
      } else if (imgElement.msRequestFullscreen) {
        /* IE11 */
        imgElement.msRequestFullscreen();
      }
    }
  };

  const handleCloseFullscreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else if ((document as any).webkitFullscreenElement) {
      /* Safari */
      (document as any).webkitExitFullscreen();
    } else if ((document as any).msFullscreenElement) {
      /* IE11 */
      (document as any).msExitFullscreen();
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("msfullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "msfullscreenchange",
        handleFullscreenChange
      );
    };
  }, []);

  return (
    <CarouselItemContainer container>
      <Grid item ultraLarge={6} desktop={6} laptop={6} tablet={12} mobile={12}>
        <ImageWrapper>
          <FullscreenImageContainer>
            <img
              ref={imgRef}
              alt="carousel item"
              src={image}
              style={{ width: "100%", height: "auto", objectFit: "contain" }}
            />
            {isFullscreen ? (
              <FullscreenButtonWrapper>
                <IconButton
                  onClick={handleCloseFullscreen}
                  style={{
                    backgroundColor: theme.palette.artome.main,
                    color: theme.palette.artome.dark,
                    borderRadius: "50%",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </FullscreenButtonWrapper>
            ) : (
              <FullscreenButtonWrapper>
                <IconButton
                  onClick={handleFullscreen}
                  style={{
                    backgroundColor: theme.palette.artome.main,
                    color: theme.palette.artome.dark,
                    borderRadius: "50%",
                  }}
                >
                  <FullscreenIcon />
                </IconButton>
              </FullscreenButtonWrapper>
            )}
          </FullscreenImageContainer>
        </ImageWrapper>
      </Grid>
      <Grid item ultraLarge={6} desktop={6} laptop={6} tablet={12} mobile={12}>
        <CarouselItemTextButtonWrapper>
          <CarouselItemButtonWrapper>
            {index !== undefined && (
              <CarouselItemButton>
                <Fab
                  disableRipple={true}
                  size="small"
                  color="primary"
                  aria-label="index"
                >
                  {index}
                </Fab>
              </CarouselItemButton>
            )}
            <CarouselItemSpan>{text}</CarouselItemSpan>
          </CarouselItemButtonWrapper>
          {link && !showButton && (
            <CarouselItemBottomButton>
              <a href={link} target="_blank" rel="noopener noreferrer">
                <Button variant="contained" size="large">
                  {linkText}
                </Button>
              </a>
            </CarouselItemBottomButton>
          )}
          {!link && showButton && (
            <CarouselItemBottomButton>
              <Button onClick={buttonPress} variant="contained" size="large">
                {buttonTitle}
              </Button>
            </CarouselItemBottomButton>
          )}
        </CarouselItemTextButtonWrapper>
      </Grid>
    </CarouselItemContainer>
  );
};

export default CarouselItem;
