import React from "react";
import { useNavigate } from "react-router-dom";

import imageHW from "../../images/S1/setup/s1-setup-hw.png";
import imageSW from "../../images/S1/setup/s1-setup-sw.png";
//import { InstallStateStore } from "../../store/store";
import TheHeader from "../../components/theHeader";
import ItemSelectItemNew from "../../components/itemSelectItemNew";

function S1SetupHome() {
  const navigate = useNavigate();
  /*
  const [s1HardwareState, s1SoftwareState] = InstallStateStore((state) => [
    state.s1HardwareState,
    state.s1SoftwareState,
  ]);
  */

  return (
    <>
      <TheHeader
        headerText={"S1 Setup"}
        icon={"arrow_back"}
        iconPress={(): void => navigate("/")}
      />
      <div className="home-container">
        <div className="home-item-container">
          <ItemSelectItemNew
            image={imageHW}
            text={"Install the Projector"}
            buttonText={"select"}
            buttonPress={() => {
              navigate("/s1-hardware");
            }}
            containerStyleName={"isi-container"}
          />
          <ItemSelectItemNew
            image={imageSW}
            text={"Setup the Projector"}
            buttonText={"select"}
            buttonPress={() => {
              navigate("/s1-software");
            }}
            containerStyleName={"isi-container"}
          />
        </div>
      </div>
    </>
  );
}

export default S1SetupHome;
