import { ArrayListType } from '../models/types';

import img1 from '../images/X20/hardware/img1.png';
import img2 from '../images/X20/hardware/img2.png';
import img3 from '../images/X20/hardware/img3.png';
import img4 from '../images/X20/hardware/img4.png';
import img5 from '../images/X20/hardware/img5.png';
import img6 from '../images/X20/hardware/img6.png';
import img7 from '../images/X20/hardware/img7.png';

const _x20HardwareImageList: Array<ArrayListType> = [
    {
        image: img1,
        text: "Roll out the front legs of the projector."
    },
    {
        image: img2,
        text: "Remove the back door from the furniture."
    },
    {
        image: img3,
        text: "Lift the projector onto the furniture from the back door side (another person is needed to help)"
    },
    {
        image: img4,
        text: "Align the front feet of the projector with the slots and roll them open so that the feet sink into the slots."
    },
    {
        image: img5,
        text: "Repeat the same for the hind legs."
    },
    {
        image: img6,
        text: "Fasten the projector to the fixture frame with four screws."
    },
    {
        image: img7,
        text: "Connect the cables to the corresponding sockets on the projector."
    }
];

export default _x20HardwareImageList;