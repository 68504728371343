import React, { FC } from "react";
import Button from "@mui/material/Button";
import { LensItemType } from "../models/types";

const LensItem: FC<LensItemType> = ({ image, text, buttonPress }) => (
  <div className="lens-item-container">

    <div className="lens-item-text-wrapper">
      <span>{text}</span>
    </div>

    <div className="lens-item-image-wrapper">
      <img src={image} alt="Lens item" />
    </div>

    <div className="lens-item-button-wrapper">
      <Button
        color="primary"
        size="large"
        onClick={buttonPress}
        variant="contained"
      >
        Select
      </Button>
    </div>

  </div>
);

export default LensItem;
