import { ArrayListType } from '../models/types';

import ELPLU04 from '../images/X20/lens/items/ELPLU04.png';
import ELPW06 from '../images/X20/lens/items/ELPW06.png';
import ELPLL08 from '../images/X20/lens/items/ELPLL08.png';
import ELPLM15 from '../images/X20/lens/items/ELPLM15.png';
import ELSE from '../images/X20/lens/items/ELSE.png';

const _x20LensList: Array<ArrayListType> = [
    {
        image: ELPLU04,
        text: "ELPLU04"
    },
    {
        image: ELPW06,
        text: "ELPW06"
    },
    {
        image: ELPLL08,
        text: "ELPLL08"
    },
    {
        image: ELPLM15,
        text: "ELPLM15"
    },
    {
        image: ELSE,
        text: "SOMETHING ELSE - NOT SUPPORTED"
    }
];

export default _x20LensList;