import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const ErrorContainer = styled.div`
  text-align: center;
  padding: 50px;
`;

const ErrorTitle = styled.h1`
  font-size: 3rem;
  color: #ff4757;
  margin-bottom: 20px;
`;

const ErrorMessage = styled.p`
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

const ErrorDetail = styled.i`
  font-size: 1.2rem;
  color: #57606f;
`;

const HomeButton = styled.button`
  margin-top: 30px;
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #1e90ff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  
  &:hover {
    background-color: #1c86ee;
  }
`;

function ErrorPage() {
  const navigate = useNavigate();

  return (
    <ErrorContainer>
      <ErrorTitle color='secodary'>Oops!</ErrorTitle>
      <ErrorMessage color='secodary'>Sorry, an unexpected error has occurred.</ErrorMessage>
      <ErrorDetail color='secodary'>The page you are looking for is not found.</ErrorDetail>
      <HomeButton color='secodary' onClick={() => navigate('/')}>
        Go to Home
      </HomeButton>
    </ErrorContainer>
  );
}

export default ErrorPage;
