import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import { logInWithEmailAndPassword } from "../API/firebase";
import MySnackbar from "./snackBar";

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (event: {
    preventDefault: () => void;
    currentTarget: HTMLFormElement | undefined;
  }) => {
    event.preventDefault();

    if (email && password !== "") {
      try {
        await logInWithEmailAndPassword(email, password);
        navigate("/");
      } catch (error: any) {
        if (error === "auth/user-not-found") {
          setSnackbarMessage("User not found");
        }
        if (error === "auth/invalid-email") {
          setSnackbarMessage("Invalid Email");
        }
        if (error === "auth/user-not-found") {
          setSnackbarMessage("User not found");
        } else {
          setSnackbarMessage("Wrong email/password");
        }
        setOpenSnackbar(true);
      }
    }
  };

  return (
    <div className="sign-x-container">
      <Box
        sx={{
          marginTop: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          overflow: "auto",
        }}
      >
        <Avatar color="primary" sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" color="secondary">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <TextField
            color="secondary"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              "& input": {
                color: "artome.light",
              },
              // Label color
              "& label": {
                color: "artome.light",
              },
              '&.Mui-focused fieldset': {
                borderColor: 'artome.light',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'artome.light',
                  borderRadius: 10,
                },
              },
            }}
          />
          <TextField
          color="secondary"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
            sx={{
              "& input": {
                color: "artome.light",
              },
              // Label color
              "& label": {
                color: "artome.light",
              },
              '&.Mui-focused fieldset': {
                borderColor: 'artome.light', // Border color when focused
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'artome.light',
                  borderRadius: 10,
                },
              },
            }}
          />
          <FormControlLabel
            control={<Checkbox value="remember" sx={{
              color: 'artome.light', // Default color
              '&.Mui-checked': {
                color: 'artome.main', // Color when checked
              },
            }} />}
            label="Remember me"
            sx={{
              // Target the label specifically
              '& .MuiFormControlLabel-label': {
                color: 'artome.light',
              }
            }}
          />
          <Button
            size="large"
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            <Typography
              component="div"
              sx={{ flexGrow: 1, color: "artome.dark" }}
            >
              Sign In
            </Typography>
          </Button>
          <Grid container>
            <Grid item tablet>
              <Link to="/forgot-password">Forgot password?</Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <MySnackbar
        message={snackbarMessage}
        onClose={function (): void {
          setOpenSnackbar(false);
        }}
        open={openSnackbar}
      />
    </div>
  );
}
