import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import { SnackBarTypes } from "../models/types";
import Alert from "@mui/material/Alert";

const MySnackbar: React.FC<SnackBarTypes> = ({
  message,
  onClose,
  open,
}) => {

  return (
    <div style={{zIndex: 999999999}}>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={onClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
        <Alert onClose={onClose} severity="error" sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
    </div>
  );
};

export default MySnackbar;
