import { useNavigate } from "react-router-dom";
import { InstallStateStore } from "../../store/store";
import Button from "@mui/material/Button";
import SummaryItem from "../../components/summary-item";
import TheHeader from "../../components/theHeader";

export default function S1InstallerStatus() {
  const navigate = useNavigate();
  const [s1HardwareState, s1SoftwareState] = InstallStateStore((state) => [
    state.s1HardwareState,
    state.s1SoftwareState,
    state.UpdateS1HardwareState,
    state.UpdateS1SoftwareState,
  ]);

  function buttonClick() {
    if (!s1HardwareState) {
      navigate("/s1-hardware");
    } else if (!s1SoftwareState) {
      navigate("/s1-software");
    } else if (s1SoftwareState && s1HardwareState) {
      navigate("/");
    }
  }

  return (
    <>
      <TheHeader
        headerText={"S1 setup summary"}
        icon={"home"}
        iconPress={(): void => navigate("/s1-setup")}
      />
      <div className="m10-summary-container">
        <div className="m10-summary-box">
          <div className="m10-summary-top-box">
            <div className="m10-summary-top-box-item-wrapper">
              <SummaryItem
                title={
                  s1HardwareState
                    ? "PROJECTOR INSTALLATION IS DONE"
                    : "PROJECTOR INSTALLATION IS NOT DONE"
                }
                done={s1HardwareState}
              />

              <SummaryItem
                title={
                  s1SoftwareState
                    ? "PROJECTOR SETUP IS DONE"
                    : "PROJECTOR SETUP IS NOT DONE"
                }
                done={s1SoftwareState}
              />
            </div>
          </div>
          <div className="m10-summary-bottom-box">
            <div className="m10-summary-bottom-box-buttons">
              <Button
                color="primary"
                size="large"
                variant="contained"
                onClick={(): void => buttonClick()}
              >
                {s1HardwareState && s1SoftwareState ? "Home" : "Next"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
