import React from "react";
import { Outlet } from "react-router-dom";
import { onAuthStateChanged, getAuth, User } from "firebase/auth";
import Splash from "./splash";
import SignInUp from "./sign-in-up";

function useAuth() {
  const [currentUser, setCurrentUser] = React.useState<User | null>();
  React.useEffect(() => {
    const unSubscribe = onAuthStateChanged(getAuth(), (user: User | null) => 
      setCurrentUser(user)
    );
    return unSubscribe;
  }, []);
  return currentUser;
}

export const ProtectedRoute = () => {
  const user = useAuth();

  //console.log("/////user autheticated", user);

  return typeof user === "undefined" ? (
    <Splash />
  ) : user ? (
    <Outlet />
  ) : (
    <SignInUp />
  );
};
