import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { SerialNumberItemType } from "../models/types";
import {
  ButtonsWrapper,
  Form,
  ImageInputWrapper,
  InfoText,
  SerialNumberContainer,
  SerialNumberImage,
  StyledButton,
  StyledTextField,
} from "../styles/serial-number-items";

interface SerialNumberItemProps extends SerialNumberItemType {
  showBackButton?: boolean;
}

const SerialNumberItem: FC<SerialNumberItemProps> = ({
  image,
  text,
  buttonText,
  secondary,
  backButtonRoute,
  inputOnChange,
  handleSubmit,
  enableButton,
  inputName,
  inputLabel,
  infoText,
  showBackButton = true,
}) => {
  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    navigate(backButtonRoute);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <SerialNumberContainer>
        <ImageInputWrapper>
          <SerialNumberImage src={image} alt="Logo" />
          {infoText && <InfoText variant="body1">{infoText}</InfoText>}
          <StyledTextField
            defaultValue={text}
            margin="normal"
            required
            name={inputName}
            label={inputLabel}
            type={inputName}
            id={inputName}
            autoComplete={inputName}
            onChange={(e) => inputOnChange(e.target.value)}
            autoFocus
            InputLabelProps={{ shrink: true }}
          />
        </ImageInputWrapper>
        <ButtonsWrapper>
          {showBackButton && (
            <StyledButton
              onClick={handleBackButtonClick}
              color="secondary"
              size="large"
              variant="contained"
            >
              Back
            </StyledButton>
          )}
          <StyledButton
            disabled={!enableButton}
            type="submit"
            color={secondary ? "primary" : "secondary"}
            size="large"
            variant="contained"
          >
            {buttonText}
          </StyledButton>
        </ButtonsWrapper>
      </SerialNumberContainer>
    </Form>
  );
};

export default SerialNumberItem;
