import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import TheHeader from "../components/theHeader";
import LensItem from "../components/lensItem";
import _x20LensList from "../data/x20LensImages";
import { LensStore } from "../store/store";

function X20LensSelect() {
  const [updateLensName] = LensStore(
    (state) => [state.updateLensName]
  );

  const navigate = useNavigate();

  function OnPress(name: string) {
    updateLensName(name)
    navigate("/x20-setup")
  }

  const LensItems: Array<any> = _x20LensList.map((d, i) => {
    return (
      <Grid key={i} desktop={4} laptop={4} tablet={4} mobile={6}>
        <LensItem
          text={d.text}
          image={d.image}
          buttonPress={(): void => OnPress(d.text)}
        />
      </Grid>
    );
  });

  return (
    <>
      <TheHeader
        headerText={"M10 Setup"}
        icon={"arrow_back"}
        iconPress={(): void => navigate("/x20-epson-sno")}
      />
      <div className="lens-container">
        <Grid
          padding={5}
          direction="row"
          alignItems="center"
          justifyContent="left"
          container
          spacing={1}
          rowSpacing={1}
          columnSpacing={{ desktop: 2, tablet: 2, mobile: 3 }}
        >
          {LensItems}
        </Grid>
      </div>
    </>
  );
}

export default X20LensSelect;
