import React, { FC } from "react";
import Button from "@mui/material/Button";
import { ProductItemType } from "../models/types";

const ProductItem: FC<ProductItemType> = ({
  productImage,
  image,
  text,
  buttonPress,
}) => (
  <div onClick={buttonPress} className="lens-item-container">
    <div className="lens-item-text-wrapper lens-item-text-wrapper-even">
      <img height={15} src={productImage} alt="Lens item" />
      <span>{text}</span>
    </div>

    <div className="lens-item-image-wrapper">
      <img src={image} alt="Lens item" />
    </div>

    <div className="lens-item-button-wrapper">
      <Button
        color="primary"
        size="medium"
        onClick={buttonPress}
        variant="outlined"
      >
        Select
      </Button>
    </div>
  </div>
);

export default ProductItem;
