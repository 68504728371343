import { ArrayListType } from "../models/types";
import { Paths } from "../enums/path.enum";

import img1 from "../images/X20/install/1.png";
import img2 from "../images/X20/install/2.png";
import img3 from "../images/X20/install/3.png";
import img4 from "../images/X20/install/4.png";
import img5 from "../images/X20/install/5.png";
import img6 from "../images/X20/install/6.png";
import img7 from "../images/X20/install/7.png";
import img8 from "../images/X20/install/8.png";
import img9 from "../images/X20/install/9.png";
import img10 from "../images/X20/install/10.png";
import img11 from "../images/X20/install/11.png";
import img12 from "../images/X20/install/12.png";
import img13 from "../images/X20/install/13.png";
import img14 from "../images/X20/install/14.png";
import img15 from "../images/X20/install/15.png";
import img16 from "../images/X20/install/16.png";
import img17 from "../images/X20/install/17.png";
import img18 from "../images/X20/install/18.png";
import img19 from "../images/X20/install/19.png";

const _x20ImageList: Array<ArrayListType> = [
  {
    image: img1,
    text: "Installer toolkit contents: cable harness and thumb screws. NOTE: The cables might already be in place.",
    link: Paths.x20SetupLegacy,
    linkText: "No installer toolkit? Click here.",
  },
  {
    image: img2,
    text: "Compatible projector models: EB-PU1006, EB-PU1008, EB-PU2010. Compatible lenses: ELPLU04 (default), ELPW06,ELPLM15, ELPLL08.",
  },
  {
    image: img3,
    text: "Place the projector on the table. Remove the lens cover and the top cover.",
  },
  {
    image: img4,
    text: "Remove the lens unlocking cover.",
  },
  {
    image: img5,
    text: "Install the lens in correct position.",
  },
  {
    image: img6,
    text: "Lock the lens.",
  },
  {
    image: img7,
    text: "Reinstall the top cover.",
  },
  {
    image: img8,
    text: "Lower the front legs of the projector.",
  },
  {
    image: img9,
    text: "Lock the wheels before proceeding. Unhinge the back door. Support the door while removing it.",
  },
  {
    image: img10,
    text: "Slide the projector inside the cabinet. Another person’s assistance is helpful in this step.",
  },
  {
    image: img11,
    text: "Align and lower the front legs to the alignment holes.",
  },
  {
    image: img12,
    text: "Align and lower the back legs to the alignment holes.",
  },
  {
    image: img13,
    text: "Attach the projector with the thumb screws.",
  },
  {
    image: img14,
    text: "Connect the cable harness to the device in the following order: 1. HDMI, 2. 3.5mm miniplug, 3. RS-232, 4. Power.",
  },
  {
    image: img15,
    text: "Attach the cable harness with zip ties.",
  },
  {
    image: img16,
    text: "Connect the cable harness to the projector.",
  },
  {
    image: img17,
    text: "Remove the plastic lens cover before using the device.",
  },
  {
    image: img18,
    text: "Plug in power and turn on the device. Wait 3 minutes until the automatic setup is ready. The device will shut down when finished.",
  },
  {
    image: img19,
    text: "Installation is ready.",
  },
];

export default _x20ImageList;
