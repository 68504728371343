import { ArrayListType } from '../models/types';

import img1 from '../images/M10/software/img1.png';
import img2 from '../images/M10/software/img2.png';
import img3 from '../images/M10/software/img3.png';
import img4 from '../images/M10/software/img4.png';
import img5 from '../images/M10/software/img5.png';
import img6 from '../images/M10/software/img6.png';
import img7 from '../images/M10/software/img7.png';
import img8 from '../images/M10/software/img8.png';
import img9 from '../images/M10/software/img9.png';
import img10 from '../images/M10/software/img10.png';
import img11 from '../images/M10/software/img11.png';
import img12 from '../images/M10/software/img12.png';

const _x10SoftwareImageList: Array<ArrayListType> = [
    {
        image: img1,
        text: "Connect the Artome M10 to power."
    },
    {
        image: img2,
        text: "Turn on the Artome M10."
    },
    {
        image: img3,
        text: "Use the Epson remote control to assign the projector settings."
    },
    {
        image: img4,
        text: "(The first setup to appear on the screen.) Select the box where the numbers 123 are facing correctly."
    },
    {
        image: img5,
        text: "Set your time settings."
    },
    {
        image: img6,
        text: "Choose your language and select NO to the next setting."
    },
    {
        image: img7,
        text: "Press Menu on the remote to open the projector settings. Navigate to Installation tab."
    },
    {
        image: img8,
        text: "Select the setting Digital Zoom and move the setting one click towards Tele."
    },
    {
        image: img9,
        text: "Navigate to the next setting Image shift."
    },
    {
        image: img10,
        text: "Shift the image to the maximum up."
    },
    {
        image: img11,
        text: "Navigate to the Display tab set Startup Screen, Home Screen Auto Disp. and Short Source Thumbnails to Off."
    },
    {
        image: img12,
        text: "Navigate to Operation tabSet Auto Power On to HDMI 1 and Power On Trigger to Plug-in Detection."
    },
];

export default _x10SoftwareImageList;