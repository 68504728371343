import React, { FC } from "react";
import IconButton from "@mui/material/IconButton";
import CheckedIcon from "@mui/icons-material/CheckCircle";
import { SummaryItemType } from "../models/types";

const SummaryItem: FC<SummaryItemType> = ({ title, done }) => (
  <div className="m10-summary-top-box-item">
    <IconButton aria-label="check" size="large">
      {done && (
        <CheckedIcon color={done ? "success" : "error"} fontSize="inherit" />
      )}
    </IconButton>
    <h3>{title}</h3>
  </div>
);

export default SummaryItem;
